@import "colors";
@import "UI";

.playbox-container {
  max-width: 63rem;
  margin: 1rem auto;
  margin-bottom: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  background-color: rgb(251, 251, 251);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 0.5rem;

  button {
    @include button;
    margin-right: 0.5rem;
    width: 10rem;
    @for $i from 1 through 17 {
      &.color-#{$i}:hover:not(:disabled) {
        background: linear-gradient(
          45deg,
          rgba(255, 255, 255, 1) 0%,
          adjust-color(nth($rainbow-17, $i), $alpha: -0.9) 100%
        );
      }
      &.color-#{$i}:active:not(:disabled) {
        background: linear-gradient(
          45deg,
          rgba(255, 255, 255, 1) 0%,
          adjust-color(nth($rainbow-17, $i), $alpha: 0) 100%
        );
        box-shadow: none;
        color: white;
      }
    }
  }

  svg {
    margin-right: 0.5rem;
  }
}
