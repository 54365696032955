@import "colors";
@import "UI";

.navbar {
    max-width: 63rem;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 0.5rem;
    color: black;
    .logo {
        font-size: 1.3rem;
        background: -webkit-linear-gradient(
            45deg,
            #1b1b1b,
            #949494,
            #1b1b1b,
            #949494,
            #1b1b1b,
            #949494,
            #1b1b1b,
            #949494,
            #1b1b1b
        );
        background-clip: border-box;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
        border-radius: 0.3rem;
        a,
        a:link,
        a:active,
        a:visited,
        a:hover {
            color: black;
            text-decoration: none;
        }
    }
    .nav-links {
        input[type="checkbox"] {
            display: none;
        }
        label.hamburger {
            @include no-select;
            display: none;
            font-size: 1.5rem;
        }
        .menu {
            display: flex;
            gap: 1.3em;
            font-size: 1rem;
            li {
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: center;
                a,
                a:link,
                a:active,
                a:visited,
                a:hover {
                    color: rgb(72, 72, 72);
                    text-decoration: none;
                    cursor: pointer;
                }
                a.active {
                    background: -webkit-linear-gradient(
                        45deg,
                        #000000,
                        #999999,
                        #000000,
                        #999999,
                        #000000,
                        #999999,
                        #000000,
                        #999999,
                        #000000
                    );
                    background-clip: border-box;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 600;
                }
                &:hover {
                    font-weight: 600;
                }
                img {
                    width: 1.3rem;
                    height: 1.3rem;
                    border-radius: 2rem;
                    margin-right: 0.3rem;
                }
            }
        }
        @media (max-width: 768px) {
            input[type="checkbox"]:checked ~ .menu {
                display: block;
            }
            label.hamburger {
                display: block;
            }
            .menu {
                display: none;
                position: absolute;
                background-color: white;
                right: 0;
                left: 0;
                transform: translateY(0.8rem);
                text-align: center;
                padding: 1rem 0;
                z-index: 9;
                li + li {
                    margin-top: 1rem;
                }
            }
        }
    }
}
