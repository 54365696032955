@use "sass:math";
@import "colors";
@import "UI";

$key-border-px: 0.07rem;
$key-white-width: 3rem;
$key-black-width: 2.25rem;
$key-white-height: 10rem;
$key-black-height: 6rem;
.keyboard-container {
  max-width: 63rem;
  text-align: center;
  margin: 0 auto;
  white-space: nowrap;
  overflow-x: auto;
  padding-bottom: 0.3rem;
  .keyboard-key {
    @include no-select;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    border: $key-border-px solid rgb(194, 194, 194);
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px 5px 1px rgba(32, 32, 32, 0.2);
    vertical-align: top;
    &.clicked {
      top: 5px;
      box-shadow: none;
    }
    &.white {
      z-index: 0;
      width: $key-white-width;
      height: $key-white-height;
      // margin-left: -$key-border-px;
      background-color: white;
      color: black;
      &:hover {
        background-color: rgb(158, 158, 158);
      }
    }
    &.black {
      z-index: 1;
      margin-left: math.div(-$key-black-width, 2);
      width: $key-black-width;
      height: $key-black-height;
      background-color: rgb(51, 51, 51);
      color: rgb(168, 168, 168);
      font-size: 90%;
      &:hover {
        background-color: rgb(158, 158, 158);
        color: black;
      }
    }
    &.black + .white {
      margin-left: math.div(-$key-black-width, 2);
    }
    @for $i from 1 through 17 {
      &.active.color-#{$i} {
        background-color: nth($rainbow-17, $i);
        color: white;
      }
      &.active.color-#{$i}:hover {
        background-color: adjust-color(nth($rainbow-17, $i), $lightness: +5%);
      }
    }
    .keyboard-key-label {
      position: absolute;
      bottom: 1rem;
      left: 0;
      right: 0;
      text-align: center;
    }
  }
}
