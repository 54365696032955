html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.keyboard-container {
  max-width: 63rem;
  text-align: center;
  white-space: nowrap;
  margin: 0 auto;
  padding-bottom: .3rem;
  overflow-x: auto;
}

.keyboard-container .keyboard-key {
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  vertical-align: top;
  border: .07rem solid #c2c2c2;
  border-radius: 0 0 5px 5px;
  display: inline-block;
  position: relative;
  box-shadow: 0 5px 1px #20202033;
}

.keyboard-container .keyboard-key.clicked {
  box-shadow: none;
  top: 5px;
}

.keyboard-container .keyboard-key.white {
  z-index: 0;
  width: 3rem;
  height: 10rem;
  color: #000;
  background-color: #fff;
}

.keyboard-container .keyboard-key.white:hover {
  background-color: #9e9e9e;
}

.keyboard-container .keyboard-key.black {
  z-index: 1;
  width: 2.25rem;
  height: 6rem;
  color: #a8a8a8;
  background-color: #333;
  margin-left: -1.125rem;
  font-size: 90%;
}

.keyboard-container .keyboard-key.black:hover {
  color: #000;
  background-color: #9e9e9e;
}

.keyboard-container .keyboard-key.black + .white {
  margin-left: -1.125rem;
}

.keyboard-container .keyboard-key.active.color-1 {
  color: #fff;
  background-color: #781c81;
}

.keyboard-container .keyboard-key.active.color-1:hover {
  background-color: #8b2196;
}

.keyboard-container .keyboard-key.active.color-2 {
  color: #fff;
  background-color: #4e1e81;
}

.keyboard-container .keyboard-key.active.color-2:hover {
  background-color: #5b2396;
}

.keyboard-container .keyboard-key.active.color-3 {
  color: #fff;
  background-color: #413992;
}

.keyboard-container .keyboard-key.active.color-3:hover {
  background-color: #4940a4;
}

.keyboard-container .keyboard-key.active.color-4 {
  color: #fff;
  background-color: #3f5cab;
}

.keyboard-container .keyboard-key.active.color-4:hover {
  background-color: #4867bc;
}

.keyboard-container .keyboard-key.active.color-5 {
  color: #fff;
  background-color: #447cbf;
}

.keyboard-container .keyboard-key.active.color-5:hover {
  background-color: #5789c6;
}

.keyboard-container .keyboard-key.active.color-6 {
  color: #fff;
  background-color: #4d95be;
}

.keyboard-container .keyboard-key.active.color-6:hover {
  background-color: #60a0c5;
}

.keyboard-container .keyboard-key.active.color-7 {
  color: #fff;
  background-color: #5ba7a6;
}

.keyboard-container .keyboard-key.active.color-7:hover {
  background-color: #6cb0af;
}

.keyboard-container .keyboard-key.active.color-8 {
  color: #fff;
  background-color: #6eb388;
}

.keyboard-container .keyboard-key.active.color-8:hover {
  background-color: #7fbc96;
}

.keyboard-container .keyboard-key.active.color-9 {
  color: #fff;
  background-color: #83ba6d;
}

.keyboard-container .keyboard-key.active.color-9:hover {
  background-color: #92c27e;
}

.keyboard-container .keyboard-key.active.color-10 {
  color: #fff;
  background-color: #9cbe5a;
}

.keyboard-container .keyboard-key.active.color-10:hover {
  background-color: #a7c56c;
}

.keyboard-container .keyboard-key.active.color-11 {
  color: #fff;
  background-color: #b4bd4c;
}

.keyboard-container .keyboard-key.active.color-11:hover {
  background-color: #bcc45f;
}

.keyboard-container .keyboard-key.active.color-12 {
  color: #fff;
  background-color: #cab842;
}

.keyboard-container .keyboard-key.active.color-12:hover {
  background-color: #d0bf56;
}

.keyboard-container .keyboard-key.active.color-13 {
  color: #fff;
  background-color: #dbab3b;
}

.keyboard-container .keyboard-key.active.color-13:hover {
  background-color: #dfb451;
}

.keyboard-container .keyboard-key.active.color-14 {
  color: #fff;
  background-color: #e59435;
}

.keyboard-container .keyboard-key.active.color-14:hover {
  background-color: #e8a04c;
}

.keyboard-container .keyboard-key.active.color-15 {
  color: #fff;
  background-color: #e7732f;
}

.keyboard-container .keyboard-key.active.color-15:hover {
  background-color: #ea8246;
}

.keyboard-container .keyboard-key.active.color-16 {
  color: #fff;
  background-color: #e24a28;
}

.keyboard-container .keyboard-key.active.color-16:hover {
  background-color: #e55d3e;
}

.keyboard-container .keyboard-key.active.color-17 {
  color: #fff;
  background-color: #d92120;
}

.keyboard-container .keyboard-key.active.color-17:hover {
  background-color: #e13332;
}

.keyboard-container .keyboard-key .keyboard-key-label {
  text-align: center;
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
}

.keySelector-container {
  max-width: 63rem;
  flex-flow: wrap;
  justify-content: space-around;
  gap: .35rem;
  margin: 1rem auto;
  padding: 0 .5rem;
  display: flex;
}

.keySelector-container .keySelector-button {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border-radius: 5px;
  padding: .3rem .5rem;
  font-size: 1.2rem;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.keySelector-container .keySelector-button.color-1 {
  color: #781c81;
}

.keySelector-container .keySelector-button.color-1.active {
  color: #fff;
  background-color: #781c81;
}

.keySelector-container .keySelector-button.color-2 {
  color: #4e1e81;
}

.keySelector-container .keySelector-button.color-2.active {
  color: #fff;
  background-color: #4e1e81;
}

.keySelector-container .keySelector-button.color-3 {
  color: #413992;
}

.keySelector-container .keySelector-button.color-3.active {
  color: #fff;
  background-color: #413992;
}

.keySelector-container .keySelector-button.color-4 {
  color: #3f5cab;
}

.keySelector-container .keySelector-button.color-4.active {
  color: #fff;
  background-color: #3f5cab;
}

.keySelector-container .keySelector-button.color-5 {
  color: #447cbf;
}

.keySelector-container .keySelector-button.color-5.active {
  color: #fff;
  background-color: #447cbf;
}

.keySelector-container .keySelector-button.color-6 {
  color: #4d95be;
}

.keySelector-container .keySelector-button.color-6.active {
  color: #fff;
  background-color: #4d95be;
}

.keySelector-container .keySelector-button.color-7 {
  color: #5ba7a6;
}

.keySelector-container .keySelector-button.color-7.active {
  color: #fff;
  background-color: #5ba7a6;
}

.keySelector-container .keySelector-button.color-8 {
  color: #6eb388;
}

.keySelector-container .keySelector-button.color-8.active {
  color: #fff;
  background-color: #6eb388;
}

.keySelector-container .keySelector-button.color-9 {
  color: #83ba6d;
}

.keySelector-container .keySelector-button.color-9.active {
  color: #fff;
  background-color: #83ba6d;
}

.keySelector-container .keySelector-button.color-10 {
  color: #9cbe5a;
}

.keySelector-container .keySelector-button.color-10.active {
  color: #fff;
  background-color: #9cbe5a;
}

.keySelector-container .keySelector-button.color-11 {
  color: #b4bd4c;
}

.keySelector-container .keySelector-button.color-11.active {
  color: #fff;
  background-color: #b4bd4c;
}

.keySelector-container .keySelector-button.color-12 {
  color: #cab842;
}

.keySelector-container .keySelector-button.color-12.active {
  color: #fff;
  background-color: #cab842;
}

.keySelector-container .keySelector-button.color-13 {
  color: #dbab3b;
}

.keySelector-container .keySelector-button.color-13.active {
  color: #fff;
  background-color: #dbab3b;
}

.keySelector-container .keySelector-button.color-14 {
  color: #e59435;
}

.keySelector-container .keySelector-button.color-14.active {
  color: #fff;
  background-color: #e59435;
}

.keySelector-container .keySelector-button.color-15 {
  color: #e7732f;
}

.keySelector-container .keySelector-button.color-15.active {
  color: #fff;
  background-color: #e7732f;
}

.keySelector-container .keySelector-button.color-16 {
  color: #e24a28;
}

.keySelector-container .keySelector-button.color-16.active {
  color: #fff;
  background-color: #e24a28;
}

.keySelector-container .keySelector-button.color-17 {
  color: #d92120;
}

.keySelector-container .keySelector-button.color-17.active {
  color: #fff;
  background-color: #d92120;
}

.ChordThumbnail-svg rect.white {
  fill: #fff;
  stroke-width: 1px;
  stroke: #bebebe;
}

.ChordThumbnail-svg rect.black {
  fill: #3f3f3f;
}

.ChordThumbnail-svg rect.active.color-1 {
  fill: #9f25ab;
}

.ChordThumbnail-svg rect.active.color-2 {
  fill: #8035d0;
}

.ChordThumbnail-svg rect.active.color-3 {
  fill: #6259be;
}

.ChordThumbnail-svg rect.active.color-4 {
  fill: #3f5cab;
}

.ChordThumbnail-svg rect.active.color-5 {
  fill: #447cbf;
}

.ChordThumbnail-svg rect.active.color-6 {
  fill: #4d95be;
}

.ChordThumbnail-svg rect.active.color-7 {
  fill: #5ba7a6;
}

.ChordThumbnail-svg rect.active.color-8 {
  fill: #6eb388;
}

.ChordThumbnail-svg rect.active.color-9 {
  fill: #83ba6d;
}

.ChordThumbnail-svg rect.active.color-10 {
  fill: #9cbe5a;
}

.ChordThumbnail-svg rect.active.color-11 {
  fill: #b4bd4c;
}

.ChordThumbnail-svg rect.active.color-12 {
  fill: #cab842;
}

.ChordThumbnail-svg rect.active.color-13 {
  fill: #dbab3b;
}

.ChordThumbnail-svg rect.active.color-14 {
  fill: #e59435;
}

.ChordThumbnail-svg rect.active.color-15 {
  fill: #e7732f;
}

.ChordThumbnail-svg rect.active.color-16 {
  fill: #e24a28;
}

.ChordThumbnail-svg rect.active.color-17 {
  fill: #e3403f;
}

.chordSelector-container {
  max-width: 63rem;
  box-sizing: border-box;
  background-color: #fbfbfb;
  border-radius: 5px;
  margin: .5rem auto;
  padding: 1rem;
}

.chordSelector-container input {
  max-width: 100%;
  color: #363636;
  height: 2.25em;
  vertical-align: top;
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border: 1.5px solid #aeaeae;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  padding: .375em .625em;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.5;
  display: inline-flex;
  position: relative;
}

.chordSelector-container input:hover {
  border-color: #858585;
}

.chordSelector-container input:active, .chordSelector-container input:focus {
  border-color: #3273dc;
  outline: 0;
  box-shadow: 0 0 0 .2rem #3273dc40;
}

.chordSelector-container input.color-1:focus {
  border-color: #781c81;
  box-shadow: 0 0 0 .2em #781c8133;
}

.chordSelector-container input.color-2:focus {
  border-color: #4e1e81;
  box-shadow: 0 0 0 .2em #4e1e8133;
}

.chordSelector-container input.color-3:focus {
  border-color: #413992;
  box-shadow: 0 0 0 .2em #41399233;
}

.chordSelector-container input.color-4:focus {
  border-color: #3f5cab;
  box-shadow: 0 0 0 .2em #3f5cab33;
}

.chordSelector-container input.color-5:focus {
  border-color: #447cbf;
  box-shadow: 0 0 0 .2em #447cbf33;
}

.chordSelector-container input.color-6:focus {
  border-color: #4d95be;
  box-shadow: 0 0 0 .2em #4d95be33;
}

.chordSelector-container input.color-7:focus {
  border-color: #5ba7a6;
  box-shadow: 0 0 0 .2em #5ba7a633;
}

.chordSelector-container input.color-8:focus {
  border-color: #6eb388;
  box-shadow: 0 0 0 .2em #6eb38833;
}

.chordSelector-container input.color-9:focus {
  border-color: #83ba6d;
  box-shadow: 0 0 0 .2em #83ba6d33;
}

.chordSelector-container input.color-10:focus {
  border-color: #9cbe5a;
  box-shadow: 0 0 0 .2em #9cbe5a33;
}

.chordSelector-container input.color-11:focus {
  border-color: #b4bd4c;
  box-shadow: 0 0 0 .2em #b4bd4c33;
}

.chordSelector-container input.color-12:focus {
  border-color: #cab842;
  box-shadow: 0 0 0 .2em #cab84233;
}

.chordSelector-container input.color-13:focus {
  border-color: #dbab3b;
  box-shadow: 0 0 0 .2em #dbab3b33;
}

.chordSelector-container input.color-14:focus {
  border-color: #e59435;
  box-shadow: 0 0 0 .2em #e5943533;
}

.chordSelector-container input.color-15:focus {
  border-color: #e7732f;
  box-shadow: 0 0 0 .2em #e7732f33;
}

.chordSelector-container input.color-16:focus {
  border-color: #e24a28;
  box-shadow: 0 0 0 .2em #e24a2833;
}

.chordSelector-container input.color-17:focus {
  border-color: #d92120;
  box-shadow: 0 0 0 .2em #d9212033;
}

.chordSelector-container .chord-container {
  width: 100%;
  flex-flow: wrap;
  display: flex;
}

.chordSelector-container .chord-container .chord {
  box-sizing: border-box;
  color: #000;
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
  flex-grow: 1;
  margin: 3px;
  padding: 2rem .5rem;
  text-decoration: none;
  box-shadow: 0 0 5px #e9e9e9;
}

.chordSelector-container .chord-container .chord:hover .name {
  background: -webkit-repeating-linear-gradient(45deg, #000, #999 20px 20px, #000 30px);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.chordSelector-container .chord-container .chord:hover.color-1 {
  background: linear-gradient(45deg, #fff 0%, #781c811a 100%);
  box-shadow: 0 0 5px #dcdcdc;
}

.chordSelector-container .chord-container .chord:hover.color-2 {
  background: linear-gradient(45deg, #fff 0%, #4e1e811a 100%);
  box-shadow: 0 0 5px #dcdcdc;
}

.chordSelector-container .chord-container .chord:hover.color-3 {
  background: linear-gradient(45deg, #fff 0%, #4139921a 100%);
  box-shadow: 0 0 5px #dcdcdc;
}

.chordSelector-container .chord-container .chord:hover.color-4 {
  background: linear-gradient(45deg, #fff 0%, #3f5cab1a 100%);
  box-shadow: 0 0 5px #dcdcdc;
}

.chordSelector-container .chord-container .chord:hover.color-5 {
  background: linear-gradient(45deg, #fff 0%, #447cbf1a 100%);
  box-shadow: 0 0 5px #dcdcdc;
}

.chordSelector-container .chord-container .chord:hover.color-6 {
  background: linear-gradient(45deg, #fff 0%, #4d95be1a 100%);
  box-shadow: 0 0 5px #dcdcdc;
}

.chordSelector-container .chord-container .chord:hover.color-7 {
  background: linear-gradient(45deg, #fff 0%, #5ba7a61a 100%);
  box-shadow: 0 0 5px #dcdcdc;
}

.chordSelector-container .chord-container .chord:hover.color-8 {
  background: linear-gradient(45deg, #fff 0%, #6eb3881a 100%);
  box-shadow: 0 0 5px #dcdcdc;
}

.chordSelector-container .chord-container .chord:hover.color-9 {
  background: linear-gradient(45deg, #fff 0%, #83ba6d1a 100%);
  box-shadow: 0 0 5px #dcdcdc;
}

.chordSelector-container .chord-container .chord:hover.color-10 {
  background: linear-gradient(45deg, #fff 0%, #9cbe5a1a 100%);
  box-shadow: 0 0 5px #dcdcdc;
}

.chordSelector-container .chord-container .chord:hover.color-11 {
  background: linear-gradient(45deg, #fff 0%, #b4bd4c1a 100%);
  box-shadow: 0 0 5px #dcdcdc;
}

.chordSelector-container .chord-container .chord:hover.color-12 {
  background: linear-gradient(45deg, #fff 0%, #cab8421a 100%);
  box-shadow: 0 0 5px #dcdcdc;
}

.chordSelector-container .chord-container .chord:hover.color-13 {
  background: linear-gradient(45deg, #fff 0%, #dbab3b1a 100%);
  box-shadow: 0 0 5px #dcdcdc;
}

.chordSelector-container .chord-container .chord:hover.color-14 {
  background: linear-gradient(45deg, #fff 0%, #e594351a 100%);
  box-shadow: 0 0 5px #dcdcdc;
}

.chordSelector-container .chord-container .chord:hover.color-15 {
  background: linear-gradient(45deg, #fff 0%, #e7732f1a 100%);
  box-shadow: 0 0 5px #dcdcdc;
}

.chordSelector-container .chord-container .chord:hover.color-16 {
  background: linear-gradient(45deg, #fff 0%, #e24a281a 100%);
  box-shadow: 0 0 5px #dcdcdc;
}

.chordSelector-container .chord-container .chord:hover.color-17 {
  background: linear-gradient(45deg, #fff 0%, #d921201a 100%);
  box-shadow: 0 0 5px #dcdcdc;
}

.chordSelector-container .chord-container .chord .name {
  margin-top: .75rem;
  font-size: 1rem;
}

.chordSelector-container .chord-container .missing-chord {
  color: #4d4d4d;
  line-height: 1.3rem;
}

.chordSelector-container .chord-container .missing-chord a, .chordSelector-container .chord-container .missing-chord a:link, .chordSelector-container .chord-container .missing-chord a:active, .chordSelector-container .chord-container .missing-chord a:visited, .chordSelector-container .chord-container .missing-chord a:hover {
  color: #7c7c7c;
}

.chordSelector-container .chord-container .missing-chord a.no-decoration {
  text-decoration: none;
}

.chordSelector-container .chord-container .missing-chord b {
  font-weight: bold;
}

.chordDetail-container {
  max-width: 63rem;
  box-sizing: border-box;
  border-radius: 5px;
  margin: .5rem auto;
  padding: 1rem;
}

.chordDetail-container.color-1 {
  background: linear-gradient(45deg, #fff 0%, #781c811a 100%);
}

.chordDetail-container.color-2 {
  background: linear-gradient(45deg, #fff 0%, #4e1e811a 100%);
}

.chordDetail-container.color-3 {
  background: linear-gradient(45deg, #fff 0%, #4139921a 100%);
}

.chordDetail-container.color-4 {
  background: linear-gradient(45deg, #fff 0%, #3f5cab1a 100%);
}

.chordDetail-container.color-5 {
  background: linear-gradient(45deg, #fff 0%, #447cbf1a 100%);
}

.chordDetail-container.color-6 {
  background: linear-gradient(45deg, #fff 0%, #4d95be1a 100%);
}

.chordDetail-container.color-7 {
  background: linear-gradient(45deg, #fff 0%, #5ba7a61a 100%);
}

.chordDetail-container.color-8 {
  background: linear-gradient(45deg, #fff 0%, #6eb3881a 100%);
}

.chordDetail-container.color-9 {
  background: linear-gradient(45deg, #fff 0%, #83ba6d1a 100%);
}

.chordDetail-container.color-10 {
  background: linear-gradient(45deg, #fff 0%, #9cbe5a1a 100%);
}

.chordDetail-container.color-11 {
  background: linear-gradient(45deg, #fff 0%, #b4bd4c1a 100%);
}

.chordDetail-container.color-12 {
  background: linear-gradient(45deg, #fff 0%, #cab8421a 100%);
}

.chordDetail-container.color-13 {
  background: linear-gradient(45deg, #fff 0%, #dbab3b1a 100%);
}

.chordDetail-container.color-14 {
  background: linear-gradient(45deg, #fff 0%, #e594351a 100%);
}

.chordDetail-container.color-15 {
  background: linear-gradient(45deg, #fff 0%, #e7732f1a 100%);
}

.chordDetail-container.color-16 {
  background: linear-gradient(45deg, #fff 0%, #e24a281a 100%);
}

.chordDetail-container.color-17 {
  background: linear-gradient(45deg, #fff 0%, #d921201a 100%);
}

.chordDetail-container .action-container {
  justify-content: flex-start;
  margin-bottom: .5rem;
  display: flex;
}

.chordDetail-container .action-container button {
  -webkit-user-select: none;
  user-select: none;
  color: #363636;
  cursor: pointer;
  white-space: nowrap;
  height: 2.25em;
  appearance: none;
  -webkit-touch-callout: none;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-right: .5rem;
  padding: .375em .75em;
  font-family: inherit;
  font-size: .9rem;
  display: flex;
  box-shadow: 0 0 5px #dadada;
}

.chordDetail-container .action-container button[disabled] {
  box-shadow: none;
  opacity: .5;
  cursor: not-allowed;
}

.chordDetail-container .action-container button svg {
  margin-right: .3rem;
}

.chordDetail-container .information-container {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  display: flex;
}

.chordDetail-container .information-container h1 {
  text-align: center;
  flex-grow: 3;
  margin-right: .5rem;
  font-size: 3rem;
  font-weight: 100;
}

.chordDetail-container .information-container .information {
  flex-grow: 1;
  font-size: 1.2rem;
}

.chordDetail-container .information-container .information div {
  margin-bottom: .4rem;
  line-height: 1.6rem;
}

.chordDetail-container .information-container .information b {
  margin-right: .7rem;
  font-weight: bold;
}

.chordDetail-container .inversion-container {
  background-color: #fff;
  border-radius: .4rem;
  margin-top: 1rem;
  box-shadow: 0 0 5px #dadada;
}

.chordDetail-container .inversion-container .inversion-header {
  -webkit-user-select: none;
  user-select: none;
  height: 2.5rem;
  color: #3f3f3f;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  font-size: 1.1rem;
  display: flex;
}

.chordDetail-container .inversion-container .inversion-content {
  flex-flow: wrap;
  padding: 1rem;
  display: flex;
}

.chordDetail-container .inversion-container .inversion-content .chord {
  cursor: pointer;
  text-align: center;
  background-color: #fff;
  border-radius: .4rem;
  flex-grow: 1;
  margin: 1px;
  padding: 2rem .5rem;
}

.chordDetail-container .inversion-container .inversion-content .chord.active.color-1 {
  background: linear-gradient(45deg, #fff 0%, #781c811a 100%);
  box-shadow: 0 0 5px #e1e1e1;
}

.chordDetail-container .inversion-container .inversion-content .chord.active.color-2 {
  background: linear-gradient(45deg, #fff 0%, #4e1e811a 100%);
  box-shadow: 0 0 5px #e1e1e1;
}

.chordDetail-container .inversion-container .inversion-content .chord.active.color-3 {
  background: linear-gradient(45deg, #fff 0%, #4139921a 100%);
  box-shadow: 0 0 5px #e1e1e1;
}

.chordDetail-container .inversion-container .inversion-content .chord.active.color-4 {
  background: linear-gradient(45deg, #fff 0%, #3f5cab1a 100%);
  box-shadow: 0 0 5px #e1e1e1;
}

.chordDetail-container .inversion-container .inversion-content .chord.active.color-5 {
  background: linear-gradient(45deg, #fff 0%, #447cbf1a 100%);
  box-shadow: 0 0 5px #e1e1e1;
}

.chordDetail-container .inversion-container .inversion-content .chord.active.color-6 {
  background: linear-gradient(45deg, #fff 0%, #4d95be1a 100%);
  box-shadow: 0 0 5px #e1e1e1;
}

.chordDetail-container .inversion-container .inversion-content .chord.active.color-7 {
  background: linear-gradient(45deg, #fff 0%, #5ba7a61a 100%);
  box-shadow: 0 0 5px #e1e1e1;
}

.chordDetail-container .inversion-container .inversion-content .chord.active.color-8 {
  background: linear-gradient(45deg, #fff 0%, #6eb3881a 100%);
  box-shadow: 0 0 5px #e1e1e1;
}

.chordDetail-container .inversion-container .inversion-content .chord.active.color-9 {
  background: linear-gradient(45deg, #fff 0%, #83ba6d1a 100%);
  box-shadow: 0 0 5px #e1e1e1;
}

.chordDetail-container .inversion-container .inversion-content .chord.active.color-10 {
  background: linear-gradient(45deg, #fff 0%, #9cbe5a1a 100%);
  box-shadow: 0 0 5px #e1e1e1;
}

.chordDetail-container .inversion-container .inversion-content .chord.active.color-11 {
  background: linear-gradient(45deg, #fff 0%, #b4bd4c1a 100%);
  box-shadow: 0 0 5px #e1e1e1;
}

.chordDetail-container .inversion-container .inversion-content .chord.active.color-12 {
  background: linear-gradient(45deg, #fff 0%, #cab8421a 100%);
  box-shadow: 0 0 5px #e1e1e1;
}

.chordDetail-container .inversion-container .inversion-content .chord.active.color-13 {
  background: linear-gradient(45deg, #fff 0%, #dbab3b1a 100%);
  box-shadow: 0 0 5px #e1e1e1;
}

.chordDetail-container .inversion-container .inversion-content .chord.active.color-14 {
  background: linear-gradient(45deg, #fff 0%, #e594351a 100%);
  box-shadow: 0 0 5px #e1e1e1;
}

.chordDetail-container .inversion-container .inversion-content .chord.active.color-15 {
  background: linear-gradient(45deg, #fff 0%, #e7732f1a 100%);
  box-shadow: 0 0 5px #e1e1e1;
}

.chordDetail-container .inversion-container .inversion-content .chord.active.color-16 {
  background: linear-gradient(45deg, #fff 0%, #e24a281a 100%);
  box-shadow: 0 0 5px #e1e1e1;
}

.chordDetail-container .inversion-container .inversion-content .chord.active.color-17 {
  background: linear-gradient(45deg, #fff 0%, #d921201a 100%);
  box-shadow: 0 0 5px #e1e1e1;
}

.chordDetail-container .inversion-container .inversion-content .chord .chord-title {
  margin-bottom: .75rem;
  font-size: 1.3rem;
  font-weight: lighter;
}

.chordDetail-container .inversion-container .inversion-content .chord .chord-name {
  margin-top: .75rem;
  font-size: 1rem;
}

.playbox-container {
  max-width: 63rem;
  box-sizing: border-box;
  background-color: #fbfbfb;
  border-radius: 5px;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  row-gap: .5rem;
  margin: 1rem auto .5rem;
  padding: .75rem 1rem;
  display: flex;
}

.playbox-container button {
  -webkit-user-select: none;
  user-select: none;
  color: #363636;
  cursor: pointer;
  white-space: nowrap;
  height: 2.25em;
  appearance: none;
  -webkit-touch-callout: none;
  width: 10rem;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-right: .5rem;
  padding: .375em .75em;
  font-family: inherit;
  font-size: .9rem;
  display: flex;
  box-shadow: 0 0 5px #dadada;
}

.playbox-container button[disabled] {
  box-shadow: none;
  opacity: .5;
  cursor: not-allowed;
}

.playbox-container button.color-1:hover:not(:disabled) {
  background: linear-gradient(45deg, #fff 0%, #781c811a 100%);
}

.playbox-container button.color-1:active:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(45deg, #fff 0%, #781c81 100%);
}

.playbox-container button.color-2:hover:not(:disabled) {
  background: linear-gradient(45deg, #fff 0%, #4e1e811a 100%);
}

.playbox-container button.color-2:active:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(45deg, #fff 0%, #4e1e81 100%);
}

.playbox-container button.color-3:hover:not(:disabled) {
  background: linear-gradient(45deg, #fff 0%, #4139921a 100%);
}

.playbox-container button.color-3:active:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(45deg, #fff 0%, #413992 100%);
}

.playbox-container button.color-4:hover:not(:disabled) {
  background: linear-gradient(45deg, #fff 0%, #3f5cab1a 100%);
}

.playbox-container button.color-4:active:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(45deg, #fff 0%, #3f5cab 100%);
}

.playbox-container button.color-5:hover:not(:disabled) {
  background: linear-gradient(45deg, #fff 0%, #447cbf1a 100%);
}

.playbox-container button.color-5:active:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(45deg, #fff 0%, #447cbf 100%);
}

.playbox-container button.color-6:hover:not(:disabled) {
  background: linear-gradient(45deg, #fff 0%, #4d95be1a 100%);
}

.playbox-container button.color-6:active:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(45deg, #fff 0%, #4d95be 100%);
}

.playbox-container button.color-7:hover:not(:disabled) {
  background: linear-gradient(45deg, #fff 0%, #5ba7a61a 100%);
}

.playbox-container button.color-7:active:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(45deg, #fff 0%, #5ba7a6 100%);
}

.playbox-container button.color-8:hover:not(:disabled) {
  background: linear-gradient(45deg, #fff 0%, #6eb3881a 100%);
}

.playbox-container button.color-8:active:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(45deg, #fff 0%, #6eb388 100%);
}

.playbox-container button.color-9:hover:not(:disabled) {
  background: linear-gradient(45deg, #fff 0%, #83ba6d1a 100%);
}

.playbox-container button.color-9:active:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(45deg, #fff 0%, #83ba6d 100%);
}

.playbox-container button.color-10:hover:not(:disabled) {
  background: linear-gradient(45deg, #fff 0%, #9cbe5a1a 100%);
}

.playbox-container button.color-10:active:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(45deg, #fff 0%, #9cbe5a 100%);
}

.playbox-container button.color-11:hover:not(:disabled) {
  background: linear-gradient(45deg, #fff 0%, #b4bd4c1a 100%);
}

.playbox-container button.color-11:active:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(45deg, #fff 0%, #b4bd4c 100%);
}

.playbox-container button.color-12:hover:not(:disabled) {
  background: linear-gradient(45deg, #fff 0%, #cab8421a 100%);
}

.playbox-container button.color-12:active:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(45deg, #fff 0%, #cab842 100%);
}

.playbox-container button.color-13:hover:not(:disabled) {
  background: linear-gradient(45deg, #fff 0%, #dbab3b1a 100%);
}

.playbox-container button.color-13:active:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(45deg, #fff 0%, #dbab3b 100%);
}

.playbox-container button.color-14:hover:not(:disabled) {
  background: linear-gradient(45deg, #fff 0%, #e594351a 100%);
}

.playbox-container button.color-14:active:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(45deg, #fff 0%, #e59435 100%);
}

.playbox-container button.color-15:hover:not(:disabled) {
  background: linear-gradient(45deg, #fff 0%, #e7732f1a 100%);
}

.playbox-container button.color-15:active:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(45deg, #fff 0%, #e7732f 100%);
}

.playbox-container button.color-16:hover:not(:disabled) {
  background: linear-gradient(45deg, #fff 0%, #e24a281a 100%);
}

.playbox-container button.color-16:active:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(45deg, #fff 0%, #e24a28 100%);
}

.playbox-container button.color-17:hover:not(:disabled) {
  background: linear-gradient(45deg, #fff 0%, #d921201a 100%);
}

.playbox-container button.color-17:active:not(:disabled) {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(45deg, #fff 0%, #d92120 100%);
}

.playbox-container svg {
  margin-right: .5rem;
}

.indexPage-container {
  max-width: 63rem;
  height: calc(100vh - 70px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.indexPage-container a, .indexPage-container a:link, .indexPage-container a:active, .indexPage-container a:visited, .indexPage-container a:hover {
  color: #000;
  font-weight: normal;
}

.indexPage-container h1 {
  background: -webkit-linear-gradient(45deg, #b827fc, #2c90fc, #b8fd33, #fec837, #fd1892);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: min(4.5rem, 12vw);
  font-weight: bold;
}

.indexPage-container h2 {
  color: #434343;
  text-align: center;
  margin: 2rem 1rem;
  font-size: 1.3rem;
  font-weight: 300;
}

.indexPage-container h3 {
  font-size: 1.1rem;
  font-weight: 300;
}

.indexPage-container .tip1 {
  color: #434343;
  border-bottom: 3px solid #0000;
  border-image: linear-gradient(to right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%) 1;
  margin-top: 4rem;
  padding: .75rem;
  font-size: 1.2rem;
}

.indexPage-container .tip2 {
  width: 100%;
}

.indexPage-container .logo-container {
  width: 610px;
  height: 90px;
}

.navbar {
  max-width: 63rem;
  box-sizing: border-box;
  color: #000;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: .8rem .5rem;
  display: flex;
}

.navbar .logo {
  background: -webkit-linear-gradient(45deg, #1b1b1b, #949494, #1b1b1b, #949494, #1b1b1b, #949494, #1b1b1b, #949494, #1b1b1b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-radius: .3rem;
  font-size: 1.3rem;
  font-weight: bold;
}

.navbar .logo a, .navbar .logo a:link, .navbar .logo a:active, .navbar .logo a:visited, .navbar .logo a:hover {
  color: #000;
  text-decoration: none;
}

.navbar .nav-links input[type="checkbox"] {
  display: none;
}

.navbar .nav-links label.hamburger {
  -webkit-user-select: none;
  user-select: none;
  font-size: 1.5rem;
  display: none;
}

.navbar .nav-links .menu {
  gap: 1.3em;
  font-size: 1rem;
  display: flex;
}

.navbar .nav-links .menu li {
  justify-content: center;
  align-items: center;
  list-style: none;
  display: flex;
}

.navbar .nav-links .menu li a, .navbar .nav-links .menu li a:link, .navbar .nav-links .menu li a:active, .navbar .nav-links .menu li a:visited, .navbar .nav-links .menu li a:hover {
  color: #484848;
  cursor: pointer;
  text-decoration: none;
}

.navbar .nav-links .menu li a.active {
  background: -webkit-linear-gradient(45deg, #000, #999, #000, #999, #000, #999, #000, #999, #000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.navbar .nav-links .menu li:hover {
  font-weight: 600;
}

.navbar .nav-links .menu li img {
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 2rem;
  margin-right: .3rem;
}

@media (width <= 768px) {
  .navbar .nav-links input[type="checkbox"]:checked ~ .menu, .navbar .nav-links label.hamburger {
    display: block;
  }

  .navbar .nav-links .menu {
    text-align: center;
    z-index: 9;
    background-color: #fff;
    padding: 1rem 0;
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    transform: translateY(.8rem);
  }

  .navbar .nav-links .menu li + li {
    margin-top: 1rem;
  }
}

.whiteboard-container {
  max-width: 63rem;
  margin: .5rem auto 0;
}

.whiteboard-container .tool-container {
  margin-bottom: 1rem;
}

.whiteboard-container .sheet-container {
  flex-flow: wrap;
  align-items: center;
  row-gap: 1rem;
  display: flex;
}

.whiteboard-container .sheet-container .bar {
  border-left: 3px solid #000;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.whiteboard-container .sheet-container .bar:last-of-type {
  border-right: 3px solid #000;
}

.whiteboard-container .sheet-container .bar .beat.b-1 {
  flex-grow: 1;
}

.whiteboard-container .sheet-container .bar .beat.b-2 {
  flex-grow: 2;
}

.whiteboard-container .sheet-container .bar .beat.b-3 {
  flex-grow: 3;
}

.whiteboard-container .sheet-container .bar .beat.b-4 {
  flex-grow: 4;
}

.whiteboard-container .sheet-container .bar .beat.edit:hover {
  background-color: #c8c8c8;
}

.whiteboard-container .sheet-container .bar .beat .chordAbbrev {
  height: 1.3rem;
  margin: .1rem .2rem;
  font-weight: 700;
}

.whiteboard-container .sheet-container .bar .beat .chordAbbrev.invalid {
  color: red;
}

.whiteboard-container .sheet-container .bar .beat .lyrics {
  margin: .1rem .2rem;
}

.whiteboard-container .sheet-container svg {
  color: #a6a6a6;
  cursor: pointer;
}

.whiteboard-container .sheet-container svg:hover {
  color: #000;
}

.notification-container {
  width: 100%;
  pointer-events: none;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: fixed;
  bottom: 1rem;
}

.notification-container .notification {
  width: -moz-fit-content;
  width: fit-content;
  pointer-events: initial;
  color: #363636;
  background-color: #fbfbfb;
  border-radius: 1rem;
  margin: .5rem 1rem;
  padding: 1.5rem 3rem;
  animation-name: animate-pop;
  animation-duration: .3s;
  animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
  position: relative;
  box-shadow: 0 5px 5px 2px #20202033;
}

.notification-container .notification svg {
  cursor: pointer;
  position: absolute;
  top: .5rem;
  right: .5rem;
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-background {
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #00000080;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-background.show {
  justify-content: center;
  align-items: center;
  display: flex;
}

.modal-background .modal-container {
  max-width: 42rem;
  max-height: 90vh;
  background-color: #f7f7f7;
  border-radius: 1rem;
  flex-grow: 1;
  margin: 1rem;
  padding: 1rem;
  overflow-y: auto;
  box-shadow: 0 5px 5px 2px #20202033;
}

.about-container {
  max-width: 63rem;
  margin: 1rem auto;
}

.about-container .decor-container {
  height: 14rem;
  position: relative;
  overflow: hidden;
}

.about-container .decor-container:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(#0000 80%, #fff);
  position: absolute;
  top: 0;
  left: 0;
}

.about-container .decor-container span {
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
}

.about-container .decor-container span.color-1 {
  background: -webkit-linear-gradient(45deg, #fff, #8b0499);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.about-container .decor-container span.color-2 {
  background: -webkit-linear-gradient(45deg, #fff, #4d0699);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.about-container .decor-container span.color-3 {
  background: -webkit-linear-gradient(45deg, #fff, #281bb0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.about-container .decor-container span.color-4 {
  background: -webkit-linear-gradient(45deg, #fff, #1c4cce);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.about-container .decor-container span.color-5 {
  background: -webkit-linear-gradient(45deg, #fff, #1e79e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.about-container .decor-container span.color-6 {
  background: -webkit-linear-gradient(45deg, #fff, #299fe2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.about-container .decor-container span.color-7 {
  background: -webkit-linear-gradient(45deg, #fff, #35cdcb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.about-container .decor-container span.color-8 {
  background: -webkit-linear-gradient(45deg, #fff, #4dd480);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.about-container .decor-container span.color-9 {
  background: -webkit-linear-gradient(45deg, #fff, #75da4d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.about-container .decor-container span.color-10 {
  background: -webkit-linear-gradient(45deg, #fff, #a7e138);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.about-container .decor-container span.color-11 {
  background: -webkit-linear-gradient(45deg, #fff, #d3e227);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.about-container .decor-container span.color-12 {
  background: -webkit-linear-gradient(45deg, #fff, #eed31e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.about-container .decor-container span.color-13 {
  background: -webkit-linear-gradient(45deg, #fff, #feb918);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.about-container .decor-container span.color-14 {
  background: -webkit-linear-gradient(45deg, #fff, #ff961b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.about-container .decor-container span.color-15 {
  background: -webkit-linear-gradient(45deg, #fff, #ff6d17);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.about-container .decor-container span.color-16 {
  background: -webkit-linear-gradient(45deg, #fff, #ff380b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.about-container .decor-container span.color-17 {
  background: -webkit-linear-gradient(45deg, #fff, #f90100);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

@media (width <= 768px) {
  .about-container .decor-container span.mobile-hide {
    display: none;
  }
}

.about-container .content-container {
  margin-bottom: 2rem;
  padding: 0 1rem;
}

.about-container .content-container h1 {
  color: #202020;
  margin: 1rem auto;
  font-size: 2rem;
  font-weight: 400;
}

.about-container .content-container div {
  color: #4d4d4d;
  line-height: 1.3rem;
}

.about-container .content-container div a, .about-container .content-container div a:link, .about-container .content-container div a:active, .about-container .content-container div a:visited, .about-container .content-container div a:hover {
  color: #7c7c7c;
}

.about-container .content-container div a.no-decoration {
  text-decoration: none;
}

.about-container .content-container div b {
  font-weight: bold;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #fff;
  font-family: Space Grotesk, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

body.disable-scrolling {
  overflow: hidden;
}

body #app {
  min-height: 100%;
  box-sizing: border-box;
}

body #app .wrapper {
  min-height: calc(100vh - 70px);
}

body #app .wrapper .Container404 {
  height: calc(100vh - 70px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

body #app .wrapper .Container404 h1 {
  color: #4e4e4e;
  margin: 1rem 0;
  font-size: 3rem;
}

body #app .wrapper .Container404 a, body #app .wrapper .Container404 a:link, body #app .wrapper .Container404 a:active, body #app .wrapper .Container404 a:visited, body #app .wrapper .Container404 a:hover {
  color: #5f5f5f;
}

body #app footer {
  height: 70px;
  color: #5f5f5f;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  font-size: .9rem;
  display: flex;
}

body #app footer pre:first-child {
  margin-right: .3rem;
}

body #app footer a, body #app footer a:link, body #app footer a:active, body #app footer a:visited, body #app footer a:hover {
  color: #5f5f5f;
}

body #app footer a.no-decoration {
  text-decoration: none;
}

body #app footer b {
  font-weight: bold;
}

/*# sourceMappingURL=index.d7fe7ddb.css.map */
